import React, { FC, useRef } from "react"
import Layout from "../../../components/Layout"
import ColumnsLayout from "../../../components/layout/columns/ColumnsLayout"
import Content from "../../../components/layout/content/Content"
import VideoEmbed from "../../../components/layout/video-embed/VideoEmbed"
import ImpactIcons from "../../../components/impact-icons/ImpactIcons"
import { PrimaryButton } from "social-supermarket-components"
import SecondaryHeader from "../../components/title/SecondaryHeader"
import UnderlineHeader from "../../components/title/UnderlineHeader"
import styled from "styled-components"
import ImageType from "../../model/ImageType"
import SEO from "../../../components/Seo"
import BannerLayout from "../../components/layout/BannerLayout"
import ProductList from "../../components/product-list/ProductList"
import { ProductType } from "social-supermarket-model"

const Container = styled.div`
  padding-bottom: 50px;
`
const ProductListContainer = styled.div`
  max-width: 1100px;
  margin: auto;
  margin-top: 40px;
`
const Minor = styled.div`
  padding-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledColumnsLayout = styled(ColumnsLayout)`
  padding-bottom: 50px;
`
const FullRangeHeader = styled(SecondaryHeader)`
  border-top: 1px solid #eaebeb;
  padding-top: 60px;
  max-width: 1200px;
  margin: auto;
`
const MinorTitles = styled(UnderlineHeader)`
  margin-top: 40px;
  margin-bottom: 10px;
`
const ImpactOneLine = styled(Content)`
  font-size: 1.3em;
`
const FullRangeButton = styled(PrimaryButton)`
  max-width: 500px;
  margin: 30px auto 30px;
`
const StyledVideoEmbed = styled(VideoEmbed)`
  margin: 20px 0 40px;
  padding: 20px 0 0;
`
const StyledImpactIcons = styled(ImpactIcons)`
  justify-content: center;
`

interface Props {
  name: string
  logo: ImageType
  banner: ImageType
  description: string
  products: ProductType[]
  oneLiner: string
  video?: string
  impactIcons: string
}

const OrganisationPage: FC<Props> = ({
  name,
  logo,
  banner,
  description,
  products,
  oneLiner,
  video,
  impactIcons,
}) => {
  const ref = useRef(null)

  const renderMajor = () => {
    return (
      <div>
        {products && products.length > 0 && (
          <FullRangeButton
            fullWidth
            onClick={() =>
              window.scrollTo({
                top: ref.current.offsetTop,
                left: 0,
                behavior: "smooth",
              })
            }
          >
            See Full Range
          </FullRangeButton>
        )}
        <ImpactOneLine html={oneLiner} />
        <Content html={description} />
        {video && <StyledVideoEmbed url={video} />}
      </div>
    )
  }

  const renderMinor = () => {
    return (
      <Minor>
        <MinorTitles>Key Points</MinorTitles>
        <StyledImpactIcons slugString={impactIcons} columns={4} />
      </Minor>
    )
  }

  return (
    <Layout fullWidth>
      <SEO title={name} description={description} />
      <Container>
        <BannerLayout bannerImage={banner} logoImage={logo} title={name} />

        <StyledColumnsLayout renderMinor={renderMinor} renderMajor={renderMajor} />

        {products && products.length > 0 && (
          <React.Fragment>
            <FullRangeHeader>Curated Selection</FullRangeHeader>
            <ProductListContainer ref={ref}>
              <ProductList products={products} collectionName={`Organisation: ${name}`} />
            </ProductListContainer>
          </React.Fragment>
        )}
      </Container>
    </Layout>
  )
}

export default OrganisationPage
